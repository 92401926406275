import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, Typography, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/system';

const Navigation = ({ signOut }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const BoldListItemText = styled(ListItemText)({
    fontWeight: 'bold',
  });

  const IndentedListItem = styled(ListItem)({
    paddingLeft: '30px',
  });

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Slot Intel Brazil
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>
          <ListItem button component={Link} to="/" onClick={toggleDrawer(false)}>
            <ListItemText primary="Home" />
          </ListItem>
          <Divider />
          <ListItem button component={Link} to="/time-machine" onClick={toggleDrawer(false)}>
            <ListItemText primary="Time Machine" />
          </ListItem>
          <Divider />
          <ListItem>
            <BoldListItemText primary="Screenshot Config" />
          </ListItem>
          <List component="div" disablePadding>
            <IndentedListItem button component={Link} to="/proxy-configuration" onClick={toggleDrawer(false)}>
              <ListItemText primary="Proxy Config" />
            </IndentedListItem>
            <IndentedListItem button component={Link} to="/websites-configuration" onClick={toggleDrawer(false)}>
              <ListItemText primary="Websites Config" />
            </IndentedListItem>
            <Divider />
          </List>
          <ListItem>
            <BoldListItemText primary="AI Config" />
          </ListItem>
          <List component="div" disablePadding>
            <IndentedListItem button component={Link} to="/model-details" onClick={toggleDrawer(false)}>
              <ListItemText primary="Model Details" />
            </IndentedListItem>
          </List>
          <Divider />
          <IndentedListItem button component={Link} to="/game-names" onClick={toggleDrawer(false)}>
              <ListItemText primary="Games List" />
            </IndentedListItem>
          <Divider />
          <ListItem>
            <BoldListItemText primary="Daily Ops" />
          </ListItem>
          <List component="div" disablePadding>
            <IndentedListItem button component={Link} to="/screenshot-errors" onClick={toggleDrawer(false)}>
              <ListItemText primary="Screenshot Errors" />
            </IndentedListItem>
            <IndentedListItem button component={Link} to="/analysis-workbench" onClick={toggleDrawer(false)}>
              <ListItemText primary="Tile Analysis Workbench" />
            </IndentedListItem>
            <IndentedListItem button component={Link} to="/game-names" onClick={toggleDrawer(false)}>
              <ListItemText primary="Game Names" />
            </IndentedListItem>
          </List>
          <Divider />
          <ListItem button onClick={() => { toggleDrawer(false)(); signOut(); }}>
            <ListItemText primary="Sign Out" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default Navigation;
