import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, CircularProgress, Box } from '@mui/material';
import axios from 'axios';

const BrightDataStatusTile = () => {
  const [balanceData, setBalanceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const API_URL = `${process.env.REACT_APP_API_URL}/system-info/brightdata`;
  const API_KEY = process.env.REACT_APP_API_KEY;

  const fetchBalanceData = async () => {
    try {
      const response = await axios.get(API_URL, {
        headers: {
          'x-api-key': API_KEY
        }
      });
      setBalanceData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data every 5 minutes
  useEffect(() => {
    fetchBalanceData();
    const interval = setInterval(fetchBalanceData, 300000);
    return () => clearInterval(interval);
  }, []);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error loading BrightData status</Typography>;

  const totalBalance = balanceData.balance + balanceData.pending_balance;

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">BrightData Status</Typography>
        <Box>
          <Typography>
            Balance: {balanceData.balance}
          </Typography>
          <Typography>
            Pending Balance: {balanceData.pending_balance}
          </Typography>
          <Typography>
            Total Balance: {totalBalance}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BrightDataStatusTile;