import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, CircularProgress, Link } from '@mui/material';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';  // Assuming you're using react-router for navigation

const StatusTile = () => {
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dots, setDots] = useState('');
  const [activeModel, setActiveModel] = useState('');
  const [availableModelsCount, setAvailableModelsCount] = useState(0);

  const API_URL = process.env.REACT_APP_API_ANALYST_URL;
  const API_KEY = process.env.REACT_APP_API_ANALYST_KEY;

  const fetchStatusAndModels = async () => {
    try {
      // Fetch available models
      const modelsResponse = await axios.get(`${API_URL}/rekognition/models`, {
        headers: {
          'x-api-key': API_KEY
        }
      });
      const models = modelsResponse.data.models;
      setAvailableModelsCount(models.length);

      // Fetch active model details
      const activeModelResponse = await axios.get(`${API_URL}/rekognition/active-model`, {
        headers: {
          'x-api-key': API_KEY
        }
      });
      const activeModelArn = activeModelResponse.data.activeModelArn;
      setActiveModel(activeModelArn);

      // Find active model's status
      const activeModelData = models.find((model) => model.ProjectVersionArn === activeModelArn);
      setStatus(activeModelData ? activeModelData.Status : 'None');

      setDots(''); // Reset dots when status is updated
    } catch (error) {
      console.error('Error fetching model status or available models:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStatusAndModels();
    const intervalId = setInterval(fetchStatusAndModels, 10000); // Refresh every 10 seconds
    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  useEffect(() => {
    if (status === 'STARTING') {
      const dotsInterval = setInterval(() => {
        setDots((prev) => (prev.length < 3 ? prev + '.' : ''));
      }, 1000);
      return () => clearInterval(dotsInterval);
    }
  }, [status]);

  const getModelName = (arn) => {
    if (!arn) return 'None';
    const arnParts = arn.split('/');
    return arnParts[arnParts.length - 2];
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          Custom Recognition Model
        </Typography>

        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">An error occurred while fetching model status.</Typography>
        ) : (
          <div>
            <Typography variant="h6" component="div">
              Current Model Name: {getModelName(activeModel)}
            </Typography>
            <Typography variant="h6" component="div">
              Current Status: <span id="status-text">{status}{status === 'STARTING' && dots}</span>
            </Typography>
            <Typography variant="h6" component="div">
              Available Models: {availableModelsCount}
            </Typography>
            <Link component={RouterLink} to="/model-details" variant="body2">
              Manage Models
            </Link>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default StatusTile;
