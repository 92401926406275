import React, { useState, useEffect } from 'react';
import { Button, Table, TableRow, TableCell, TableBody, TableHead, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Modal, Box } from '@mui/material';

// Proxy Configuration Component
const ProxyConfiguration = () => {
  const [proxyConfigs, setProxyConfigs] = useState([]); // Stores the list of proxies
  const [open, setOpen] = useState(false); // Controls dialog visibility
  const [selectedProxyConfig, setSelectedProxyConfig] = useState(null); // Tracks the proxy being edited
  const [formState, setFormState] = useState({
    name: '',
    description: '',
    url: '',
    username: '',
    password: '',
  });
  const [screenshot, setScreenshot] = useState(null);
  const [isTesting, setIsTesting] = useState(false);
  const [screenshotModalOpen, setScreenshotModalOpen] = useState(false);



  useEffect(() => {
    fetchProxyConfigs();
  }, []);

  // Fetch all proxy configurations
  const fetchProxyConfigs = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/proxys`, {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
      });
      const data = await response.json();
      setProxyConfigs(data);
    } catch (error) {
      console.error('Error fetching proxy configurations:', error);
    }
  };

  // Open dialog to add or edit proxy
  const handleOpen = (proxyConfig = null) => {
    setSelectedProxyConfig(proxyConfig);
    setFormState(proxyConfig || { name: '', description: '', url: '', username: '', password: '' });
    setOpen(true);
  };

  // Close dialog
  const handleClose = () => {
    setOpen(false);
    setSelectedProxyConfig(null);
  };

  // Handle form input changes
  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  // Create or update a proxy configuration
  const handleSubmit = async () => {
    try {
      const url = selectedProxyConfig
        ? `${process.env.REACT_APP_API_URL}/proxys/${selectedProxyConfig.id}`
        : `${process.env.REACT_APP_API_URL}/proxys`;
      const method = selectedProxyConfig ? 'PUT' : 'POST';

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(formState),
      });

      if (response.ok) {
        fetchProxyConfigs(); // Refresh list
        handleClose(); // Close dialog
      }
    } catch (error) {
      console.error('Error saving proxy configuration:', error);
    }
  };

  const handleDeleteProxyConfig = async (proxyConfig) => {
    if (!proxyConfig || !proxyConfig.id) {
      console.error("Invalid proxy configuration ID");
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/proxys/${proxyConfig.id}`, {
        method: 'DELETE',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
      });
  
      if (response.ok) {
        // Successfully deleted the proxy
        fetchProxyConfigs(); // Refresh the proxy list
      } else {
        // Handle non-OK responses
        const errorData = await response.json();
        console.error(`Error deleting proxy: ${response.status}`, errorData);
      }
    } catch (error) {
      console.error('Error deleting proxy configuration:', error);
    }
  };
  

  const handleTestProxy = async (proxyConfig) => {
    setIsTesting(true); // Disable the button and change text

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/proxys/${proxyConfig.id}/screenshot`, {
            method: 'GET',
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY,
            },
        });

        if (response.ok) {
            const data = await response.json();
            setScreenshot(data.screenshot);
            setScreenshotModalOpen(true);
        } else {
            console.error('Error taking screenshot:', response.statusText);
            // Consider adding user-friendly error handling here
        }
    } catch (error) {
        console.error('Error taking screenshot:', error);
        // Consider adding user-friendly error handling here
    } finally {
        setIsTesting(false); // Re-enable the button
    }
  };

  const handleCopyProxyConfig = (proxyConfig) => {
      const proxyString = `http://${proxyConfig.username}:${proxyConfig.password}@${proxyConfig.url}`;
      navigator.clipboard.writeText(proxyString).then(() => {
          alert('Proxy config copied to clipboard');
      }).catch((error) => {
          console.error('Error copying proxy config to clipboard:', error);
      });
  };



  const handleCloseScreenshotModal = () => {
    setScreenshotModalOpen(false);
    setScreenshot(null);
  };

  return (
    <div>
      <h2>Proxy Configurations</h2>
      <Button onClick={() => handleOpen()}>Add Proxy Configuration</Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>URL</TableCell>
            <TableCell>Username</TableCell>
            <TableCell>Password</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {proxyConfigs.map((proxyConfig) => (
            <TableRow key={proxyConfig.id}>
              <TableCell>{proxyConfig.name}</TableCell>
              <TableCell>{proxyConfig.description}</TableCell>
              <TableCell>{proxyConfig.url}</TableCell>
              <TableCell>{proxyConfig.username}</TableCell>
              <TableCell>{proxyConfig.password}</TableCell>
              <TableCell>
                <Button onClick={() => handleOpen(proxyConfig)}>Edit</Button>
                <Button onClick={() => handleDeleteProxyConfig(proxyConfig)}>Delete</Button>
                <Button onClick={() => handleTestProxy(proxyConfig)} disabled={isTesting}>{isTesting ? 'Testing' : 'Test'}</Button>
                <Button onClick={() => handleCopyProxyConfig(proxyConfig)}>Copy Proxy Config</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{selectedProxyConfig ? 'Edit Proxy Configuration' : 'Add Proxy Configuration'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            type="text"
            fullWidth
            value={formState.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="description"
            label="Description"
            type="text"
            fullWidth
            value={formState.description}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="url"
            label="URL"
            type="text"
            fullWidth
            value={formState.url}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="username"
            label="Username"
            type="text"
            fullWidth
            value={formState.username}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="password"
            label="Password"
            type="password"
            fullWidth
            value={formState.password}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>{selectedProxyConfig ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>

      <Modal
                open={screenshotModalOpen}
                onClose={handleCloseScreenshotModal}
                aria-labelledby="screenshot-modal-title"
                aria-describedby="screenshot-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 800,
                    bgcolor: 'background.paper',
                    border:   
 '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    maxHeight:   
 '90vh', 
                    maxWidth: '90vw',  
                    overflow: 'auto', 
                }}>
                    <h2 id="screenshot-modal-title">Screenshot</h2>
                    {screenshot && <img src={`data:image/png;base64,${screenshot}`} alt="Screenshot" style={{ maxWidth: '100%' }} />}
                </Box>
            </Modal>

    </div>
  );
};

export default ProxyConfiguration;

