import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@mui/material';

const ScreenshotErrorsTable = () => {
  const [errors, setErrors] = useState([]);
  const [proxys, setProxys] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [retrying, setRetrying] = useState(null);

  // API configuration
  const apiKey = process.env.REACT_APP_API_KEY;
  const apiUrl = process.env.REACT_APP_API_URL;

  // Fetch errors and proxies when component mounts
  useEffect(() => {
    const fetchErrorsAndProxys = async () => {
      try {
        // Fetch errors
        const errorsResponse = await axios.get(`${apiUrl}/websites/errors`, {
          headers: { 'x-api-key': apiKey },
        });
        // Log the fetched errors to verify their structure
        console.log('Fetched Errors:', errorsResponse.data.errors);

        // Fetch proxys
        const proxysResponse = await axios.get(`${apiUrl}/proxys`, {
          headers: { 'x-api-key': apiKey },
        });

        setErrors(errorsResponse.data.errors);

        // Transform the proxys array into an object for easier lookups
        const proxysObj = proxysResponse.data.reduce((acc, proxy) => {
          acc[proxy.id] = proxy.name;
          return acc;
        }, {});

        setProxys(proxysObj);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchErrorsAndProxys();
  }, [apiKey, apiUrl]);

  // Helper function to group errors by date
  const groupErrorsByDate = () => {
    const groupedErrors = {};

    errors.forEach((error) => {
      const date = error.Timestamp.split('T')[0];
      if (!groupedErrors[date]) {
        groupedErrors[date] = [];
      }
      groupedErrors[date].push(error);
    });

    return groupedErrors;
  };

  // Helper function to check if the error occurred today (in UTC)
  const isErrorToday = (errorTimestamp) => {
    const todayUTC = new Date().toISOString().split('T')[0]; // Get today's date in UTC
    const errorDateUTC = errorTimestamp.split('T')[0]; // Extract date from the error timestamp
    return todayUTC === errorDateUTC;
  };

  // Handle retry action
  const handleRetry = async (websitesDBID, websitesDataID) => {
    setRetrying(websitesDataID); // Set the retrying state to show loading indicator for the specific error
  
    try {
      console.log('Retry initiated for WebsitesDBID:', websitesDBID, 'WebsitesDataID:', websitesDataID); // Log the values
  
      const payload = {
        WebsitesDBID: websitesDBID,
        WebsitesDataID: websitesDataID,
      };
  
      console.log('Payload being sent:', payload); // Log the payload being sent
  
      // Make the API call
      const response = await axios.post(`${apiUrl}/retry`, payload, {
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'application/json', // Ensure the content type is set
        },
      });
  
      console.log('API Response:', response); // Log the API response
  
      if (response.status === 200) {
        alert('Retry successful!');
        // Optionally, remove the error entry from the list
        setErrors((prevErrors) => prevErrors.filter((err) => err.WebsitesDataID !== websitesDataID));
      } else {
        alert('Retry failed. Please try again later.');
      }
    } catch (error) {
      console.error('Error during retry:', error); // Log the error
      alert('An error occurred while retrying.');
    }
  
    setRetrying(null); // Reset the retrying state
  };
  

  const groupedErrors = groupErrorsByDate();

  return (
    <Box p={4}>
      <Typography variant="h4" gutterBottom>
        Screenshot Errors
      </Typography>

      {isLoading ? (
        <Typography>Loading...</Typography>
      ) : (
        Object.keys(groupedErrors).map((date) => (
          <Box key={date} mb={4}>
            <Typography variant="h6" gutterBottom>
              {new Date(date).toLocaleDateString('en-GB', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </Typography>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>URL</TableCell>
                  <TableCell>Error Details</TableCell>
                  <TableCell>Time Stamp</TableCell>
                  <TableCell>Proxy Name</TableCell>
                  <TableCell>Retry</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupedErrors[date].map((error) => {
                  const proxyId = error.ProxyId;
                  const proxyName = proxys[proxyId] || 'Unknown Proxy';

                  return (
                    <TableRow key={error.id}>
                      <TableCell>
                        <a href={error.URL} target="_blank" rel="noopener noreferrer">
                          {error.URL}
                        </a>
                      </TableCell>
                      <TableCell>{error.ErrorDetails}</TableCell>
                      <TableCell>{error.Timestamp}</TableCell>
                      <TableCell>{proxyName}</TableCell>
                      <TableCell>
                        {isErrorToday(error.Timestamp) && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleRetry(error.WebsitesDBID, error.id)} // Use "id" for WebsitesDataID
                            disabled={retrying === error.id}
                          >
                            {retrying === error.id ? 'Retrying...' : 'Retry'}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        ))
      )}
    </Box>
  );
};

export default ScreenshotErrorsTable;
