import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Tabs,
  Tab,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Autocomplete,
} from '@mui/material';
import { Delete as DeleteIcon, Info as InfoIcon, Edit as EditIcon } from '@mui/icons-material';

const GamesStudiosPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [games, setGames] = useState([]);
  const [studios, setStudios] = useState([]);
  const [gamesByStudio, setGamesByStudio] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [loading, setLoading] = useState(true);

  // States for Create Game and Create Studio
  const [createGameDialogOpen, setCreateGameDialogOpen] = useState(false);
  const [createStudioDialogOpen, setCreateStudioDialogOpen] = useState(false);
  const [newGameName, setNewGameName] = useState('');
  const [selectedStudioID, setSelectedStudioID] = useState('');
  const [newStudioName, setNewStudioName] = useState('');
  const [deleteStudioDialogOpen, setDeleteStudioDialogOpen] = useState(false);
  const [studioToDelete, setStudioToDelete] = useState(null);
  const [gamesAssociatedCount, setGamesAssociatedCount] = useState(0);
  const [updateStudioDialogOpen, setUpdateStudioDialogOpen] = useState(false);
  const [gameToUpdate, setGameToUpdate] = useState(null);
  const [updatedStudioID, setUpdatedStudioID] = useState('');

  const fetchGamesAndStudios = async () => {
    try {
      // Fetch games
      console.log('Fetching games...');
      const gamesResponse = await axios.get(`${process.env.REACT_APP_API_GAMES_STUDIOS_URL}/games`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_GAMES_STUDIOS_KEY,
        },
      });
      if (gamesResponse.data && gamesResponse.data.games) {
        console.log('Games fetched:', gamesResponse.data.games);
        setGames(Array.isArray(gamesResponse.data.games) ? gamesResponse.data.games : []);
      } else {
        console.error('Games data is not an array:', gamesResponse.data);
        setGames([]);
      }

      // Fetch studios
      console.log('Fetching studios...');
      const studiosResponse = await axios.get(`${process.env.REACT_APP_API_GAMES_STUDIOS_URL}/studios`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_GAMES_STUDIOS_KEY,
        },
      });
      if (studiosResponse.data && studiosResponse.data.studios) {
        console.log('Studios fetched:', studiosResponse.data.studios);
        setStudios(Array.isArray(studiosResponse.data.studios) ? studiosResponse.data.studios : []);
      } else {
        console.error('Studios data is not an array:', studiosResponse.data);
        setStudios([]);
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching games or studios:', error);
    }
  };


  useEffect(() => {
    fetchGamesAndStudios();
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleDeleteGame = async (gameId) => {
    if (window.confirm('Are you sure you want to delete this game?')) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_GAMES_STUDIOS_URL}/games`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_GAMES_STUDIOS_KEY,
          },
          data: {
            GameID: gameId,
          },
        });
        setGames((prevGames) => prevGames.filter((game) => game.GameID !== gameId));
        fetchGamesAndStudios();
      } catch (error) {
        console.error('Error deleting game:', error);
      }
    }
  };

  const handleOpenDialog = (studio) => {
    setGamesByStudio(games.filter((game) => game.StudioID === studio.StudioID));
    setDialogTitle(`Games by ${studio.Name}`);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Handle Create Studio
  const handleCreateStudio = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_GAMES_STUDIOS_URL}/studios`,
        { Name: newStudioName },
        {
          headers: { 'x-api-key': process.env.REACT_APP_API_GAMES_STUDIOS_KEY },
        }
      );
      setStudios((prevStudios) => [...prevStudios, response.data]);
      setCreateStudioDialogOpen(false);
      setNewStudioName('');
      fetchGamesAndStudios();
    } catch (error) {
      console.error('Error creating studio:', error);
    }
  };

  // Handle Delete Studio
  const handleDeleteStudio = (studio) => {
    const associatedGames = games.filter((game) => game.StudioID === studio.StudioID);
    setGamesAssociatedCount(associatedGames.length);
    setStudioToDelete(studio);
    setDeleteStudioDialogOpen(true);
  };

  const confirmDeleteStudio = async () => {
    if (studioToDelete) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_GAMES_STUDIOS_URL}/studios`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_GAMES_STUDIOS_KEY,
          },
          data: {
            StudioID: studioToDelete.StudioID,
          },
        });
        setStudios((prevStudios) => prevStudios.filter((studio) => studio.StudioID !== studioToDelete.StudioID));
        setGames((prevGames) =>
          prevGames.map((game) =>
            game.StudioID === studioToDelete.StudioID ? { ...game, StudioID: 'unknown' } : game
          )
        );
        setDeleteStudioDialogOpen(false);
        setStudioToDelete(null);
        fetchGamesAndStudios();
      } catch (error) {
        console.error('Error deleting studio:', error);
      }
    }
  };

  // Handle Update Studio for Game
  const handleUpdateStudio = (game) => {
    setGameToUpdate(game);
    setUpdatedStudioID(game.StudioID);
    setUpdateStudioDialogOpen(true);
  };

  const confirmUpdateStudio = async () => {
    if (gameToUpdate && updatedStudioID) {
      try {
        await axios.patch(`${process.env.REACT_APP_API_GAMES_STUDIOS_URL}/games`, {
          GameID: gameToUpdate.GameID,
          StudioID: updatedStudioID,
        }, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_GAMES_STUDIOS_KEY,
          },
        });
        setGames((prevGames) =>
          prevGames.map((game) =>
            game.GameID === gameToUpdate.GameID ? { ...game, StudioID: updatedStudioID } : game
          )
        );
        setUpdateStudioDialogOpen(false);
        setGameToUpdate(null);
        fetchGamesAndStudios();
      } catch (error) {
        console.error('Error updating studio:', error);
      }
    }
    
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={selectedTab} onChange={handleTabChange} centered>
        <Tab label="Games" />
        <Tab label="Studios" />
      </Tabs>

      {selectedTab === 0 && (
        <Box>
          <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Game Name</TableCell>
                  <TableCell>Studio Name</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {games.map((game) => (
                  <TableRow key={game.GameID}>
                    <TableCell>{game.Name}</TableCell>
                    <TableCell>
                      {studios.find((studio) => studio.StudioID === game.StudioID)?.Name || 'Unknown'}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleDeleteGame(game.GameID)}
                      >
                        Delete
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={() => handleUpdateStudio(game)}
                        sx={{ marginLeft: 2 }}
                      >
                        Update Studio
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {selectedTab === 1 && (
        <Box>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: 2, marginBottom: 2 }}
            onClick={() => setCreateStudioDialogOpen(true)}
          >
            CREATE STUDIO
          </Button>
          <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Studio Name</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studios.map((studio) => (
                  <TableRow key={studio.StudioID}>
                    <TableCell>{studio.Name}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<InfoIcon />}
                        onClick={() => handleOpenDialog(studio)}
                      >
                        View Games
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleDeleteStudio(studio)}
                        sx={{ marginLeft: 2 }}
                      >
                        Delete Studio
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
 
      {/* Dialog for viewing games by studio */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Game Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {gamesByStudio.map((game) => (
                <TableRow key={game.GameID}>
                  <TableCell>{game.Name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Create Studio Dialog */}
      <Dialog open={createStudioDialogOpen} onClose={() => setCreateStudioDialogOpen(false)}>
        <DialogTitle>Create Studio</DialogTitle>
        <DialogContent>
          <TextField
            label="Studio Name"
            fullWidth
            value={newStudioName}
            onChange={(e) => setNewStudioName(e.target.value)}
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreateStudioDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleCreateStudio} disabled={!newStudioName}>
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Studio Confirmation Dialog */}
      <Dialog open={deleteStudioDialogOpen} onClose={() => setDeleteStudioDialogOpen(false)}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <p>
            WARNING: There are {gamesAssociatedCount} games associated with this studio. Deleting this studio will
            mark these games as having an "unknown" studio. Do you want to continue?
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteStudioDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" color="error" onClick={confirmDeleteStudio}>
            Go Ahead
          </Button>
        </DialogActions>
      </Dialog>

      {/* Update Studio Dialog */}
      <Dialog open={updateStudioDialogOpen} onClose={() => setUpdateStudioDialogOpen(false)}>
        <DialogTitle>Update Studio</DialogTitle>
        <DialogContent sx={{ minWidth: '400px' }}>
          <Autocomplete
            options={studios}
            getOptionLabel={(option) => option.Name}
            value={studios.find((studio) => studio.StudioID === updatedStudioID) || null}
            onChange={(event, newValue) => {
              setUpdatedStudioID(newValue ? newValue.StudioID : '');
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Studio"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUpdateStudioDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={confirmUpdateStudio} disabled={!updatedStudioID}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GamesStudiosPage;
