import React, { useState, useEffect } from 'react';
import { withAuthenticator, Heading, SelectField, Flex, View, Text, Alert, Button } from '@aws-amplify/ui-react';
import { CircularProgress } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { Tabs, Tab, Table, TableHead, TableBody, TableRow, TableCell, Typography } from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material'; // Import the tick icon
import moment from 'moment'; // Import moment for formatting timestamp

import axios from 'axios';  //API call support



const TimeMachine = () => {
    const [websites, setWebsites] = useState([]);
    const [selectedWebsite, setSelectedWebsite] = useState('');
    const [screenshots, setScreenshots] = useState([]);
    const [currentScreenshotIndex, setCurrentScreenshotIndex] = useState(0);
    const [loadingScreenshots, setLoadingScreenshots] = useState(false);
    const [error, setError] = useState('');
    const [analysisResults, setAnalysisResults] = useState(null);
    const [loadingAnalysis, setLoadingAnalysis] = useState(false);
    const [analysisStatus, setAnalysisStatus] = useState('');
    const [selectedTab, setSelectedTab] = useState(0); // 0 for Screenshot, 1 for Tiles
    const [tileImages, setTileImages] = useState([]); // Store individual tile images
    const [imageUrl, setImageUrl] = useState(''); // Store the signed URL for the current screenshot
    const [loadingTiles, setLoadingTiles] = useState(false); // New state for loading tiles
    const [games, setGames] = useState([]);  //used to load the games list
    const [studios, setStudios] = useState([]);  //used to load the studios list
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch websites
                console.log('Fetching websites...');
                await fetchWebsites();
                console.log('Websites fetched successfully.');
    
                // Fetch games
                console.log('Fetching games...');
                const gamesResponse = await axios.get(`${process.env.REACT_APP_API_GAMES_STUDIOS_URL}/games`, {
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_GAMES_STUDIOS_KEY,
                    },
                });
    
                let gamesArray = [];
                if (Array.isArray(gamesResponse.data)) {
                    console.log('Games fetched:', gamesResponse.data);
                    gamesArray = gamesResponse.data;
                } else if (gamesResponse.data?.games) {
                    console.log('Converting games object to array:', gamesResponse.data.games);
                    gamesArray = Array.isArray(gamesResponse.data.games) ? gamesResponse.data.games : [];
                } else {
                    console.error('Unexpected format for games data:', gamesResponse.data);
                }
                setGames(gamesArray);
    
                // Fetch studios
                console.log('Fetching studios...');
                const studiosResponse = await axios.get(`${process.env.REACT_APP_API_GAMES_STUDIOS_URL}/studios`, {
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_GAMES_STUDIOS_KEY,
                    },
                });
    
                let studiosArray = [];
                if (Array.isArray(studiosResponse.data)) {
                    console.log('Studios fetched:', studiosResponse.data);
                    studiosArray = studiosResponse.data;
                } else if (studiosResponse.data?.studios) {
                    console.log('Converting studios object to array:', studiosResponse.data.studios);
                    studiosArray = Array.isArray(studiosResponse.data.studios) ? studiosResponse.data.studios : [];
                } else {
                    console.error('Unexpected format for studios data:', studiosResponse.data);
                }
                setStudios(studiosArray);
    
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, []);
    

 
    


    // Fetch unique website names
    const fetchWebsites = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/websites/unique-names`, {
                method: 'GET',
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY,
                },
            });
            const fetchedWebsites = await response.json();
            setWebsites(fetchedWebsites);
        } catch (error) {
            console.error('Error fetching websites:', error);
            setError('Error fetching website names.');
        }
    };

    // Fetch screenshots when the website selection changes
    useEffect(() => {
        if (selectedWebsite) {
            fetchScreenshots(selectedWebsite);
        }
    }, [selectedWebsite]);

    const getSignedUrl = async (s3Url) => {
        if (!s3Url) return '';
        try {
            // Extract the key from the s3Url
            const key = s3Url.replace('s3://', '').split('/').slice(1).join('/');

            const apiUrl = `${process.env.REACT_APP_API_URL}/screenshotssigned/${encodeURIComponent(key)}`;

            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY,
                },
            });

            if (!response.ok) {
                console.error('Error response from signed URL API:', response.statusText);
                return '';
            }

            // Get the signed URL as plain text
            const signedUrl = await response.text();
            console.log('Received signed URL:', signedUrl);

            return signedUrl;
        } catch (error) {
            console.error('Error getting signed URL:', error);
            return '';
        }
    };

    const fetchScreenshots = async (websiteName) => {
        setLoadingScreenshots(true);
        setError('');
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/screenshots?websiteName=${encodeURIComponent(websiteName)}`,
                {
                    method: 'GET',
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                    },
                }
            );
            const fetchedScreenshots = await response.json();
            console.log('Fetched screenshots:', fetchedScreenshots);

            // Store the screenshots without fetching signed URLs
            setScreenshots(fetchedScreenshots.screenshots);
            setCurrentScreenshotIndex(0);
        } catch (error) {
            console.error('Error fetching screenshots:', error);
            setError('Failed to load screenshots.');
        }
        setLoadingScreenshots(false);
    };

    // Fetch analysis and signed URL when the screenshot changes
    useEffect(() => {
        if (screenshots.length > 0) {
            const currentScreenshot = screenshots[currentScreenshotIndex];
            if (currentScreenshot) {
                console.log('Fetching analysis for websitesDataId:', currentScreenshot.id?.S);
                fetchAnalysis(currentScreenshot.id?.S);

                // Fetch signed URL for the current screenshot
                fetchImageUrl(currentScreenshot.ImageLocation?.S);

                // Reset tiles and set loading state
                setTileImages([]);
                setLoadingTiles(true);
            }
        }
    }, [currentScreenshotIndex, screenshots]);

    const fetchAnalysis = async (websitesDataId) => {
        if (!websitesDataId) return;
        setLoadingAnalysis(true);
        setAnalysisStatus('Loading Analysis');
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_ANALYST_URL}/get-unconfirmed-analysis/${websitesDataId}`,
                {
                    method: 'GET',
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_ANALYST_KEY,
                    },
                }
            );

            if (response.status === 404) {
                setAnalysisStatus('No Analysis Found');
                setAnalysisResults(null);
            } else {
                const resultData = await response.json();
                console.log('Analysis results:', resultData);
                setAnalysisResults(resultData);
                setAnalysisStatus('Loaded Analysis');
            }
        } catch (error) {
            console.error('Error fetching analysis results:', error);
            setAnalysisStatus('Error fetching analysis results');
        }
        setLoadingAnalysis(false);
    };

    const fetchImageUrl = async (imageLocation) => {
        if (!imageLocation) return;
        const signedUrl = await getSignedUrl(imageLocation);
        setImageUrl(signedUrl);
    };

    // Use effect to process tile images when both imageUrl and analysisResults are ready
    useEffect(() => {
        if (imageUrl && analysisResults && analysisResults.length > 0) {
            processTileImages(analysisResults);
        } else {
            setTileImages([]);
            setLoadingTiles(false);
        }
    }, [imageUrl, analysisResults]);

    const processTileImages = async (analysisResults) => {
        if (!imageUrl || !analysisResults || analysisResults.length === 0) {
            setTileImages([]);
            setLoadingTiles(false);
            return;
        }

        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.src = imageUrl;

        img.onload = () => {
            const tileImagesArray = analysisResults.map((box) => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                // Calculate the dimensions of the tile
                const sx = img.width * parseFloat(box.BoundingBox?.M?.Left?.N);
                const sy = img.height * parseFloat(box.BoundingBox?.M?.Top?.N);
                const sWidth = img.width * parseFloat(box.BoundingBox?.M?.Width?.N);
                const sHeight = img.height * parseFloat(box.BoundingBox?.M?.Height?.N);

                canvas.width = sWidth;
                canvas.height = sHeight;

                ctx.drawImage(img, sx, sy, sWidth, sHeight, 0, 0, sWidth, sHeight);

                // Get the data URL of the cropped image
                const dataUrl = canvas.toDataURL();

                // Return the data URL along with the original dimensions
                return {
                    dataUrl,
                    width: sWidth,
                    height: sHeight,
                };
            });

            setTileImages(tileImagesArray);
            setLoadingTiles(false); // Tiles are ready
        };

        img.onerror = (error) => {
            console.error('Error loading image for tile processing:', error);
            setTileImages([]);
            setLoadingTiles(false);
        };
    };

    const handleNextScreenshot = () => {
        const nextIndex = currentScreenshotIndex - 1;
        if (nextIndex >= 0) {
            setCurrentScreenshotIndex(nextIndex);
        }
    };

    const handlePreviousScreenshot = () => {
        const prevIndex = currentScreenshotIndex + 1;
        if (prevIndex < screenshots.length) {
            setCurrentScreenshotIndex(prevIndex);
        }
    };

    const handleWebsiteChange = (e) => {
        setSelectedWebsite(e.target.value);
        setScreenshots([]);
        setCurrentScreenshotIndex(0);
        setTileImages([]);
        setImageUrl('');
        setAnalysisResults(null);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const currentScreenshot = screenshots.length > 0 ? screenshots[currentScreenshotIndex] : null;

    // Logging the state for debugging
    useEffect(() => {
        console.log('Websites:', websites);
        console.log('Selected Website:', selectedWebsite);
        console.log('Screenshots:', screenshots);
        console.log('Current Screenshot Index:', currentScreenshotIndex);
        console.log('Current Screenshot:', currentScreenshot);
        console.log('Image URL:', imageUrl);
        console.log('Analysis Results:', analysisResults);
        console.log('Analysis Status:', analysisStatus);
        console.log('Tile Images:', tileImages);
        console.log('Loading Tiles:', loadingTiles);
    }, [
        websites,
        selectedWebsite,
        screenshots,
        currentScreenshotIndex,
        currentScreenshot,
        imageUrl,
        analysisResults,
        analysisStatus,
        tileImages,
        loadingTiles,
    ]);


    //look up the studio details 
    const getStudioDetailsFromGameID = (gameID) => {
        const game = games.find((game) => game.GameID === gameID);
        if (game) {
          // Use the StudioID from the game to find the corresponding studio
          const studio = studios.find((studio) => studio.StudioID === game.StudioID);
          return {
            studioID: game.StudioID,
            studioName: studio ? studio.Name : 'Unknown',
          };
        }
        return {
          studioID: 'Unknown',
          studioName: 'Unknown',
        };
      };
      


    //Render the tooltip layout
    const renderTileTooltipContent = (box) => {
        if (box.Status?.S === 'COMPLETE') {
            const game = games.find((g) => g.GameID === box.ConfirmedMatch?.M?.GameID?.S);
            const studio = studios.find((s) => s.StudioID === game?.StudioID);
            
            return (
                <>
                    <strong>Completed Analysis</strong>
                    <br />
                    Game Name: {game ? game.Name : 'Unknown'}
                    <br />
                    Game ID: {box.ConfirmedMatch?.M?.GameID?.S || 'Unknown'}
                    <br />
                    Studio Name: {studio ? studio.Name : 'Unknown'}
                    <br />
                    Studio ID: {studio ? studio.StudioID : 'Unknown'}
                    <br />
                    Completed At: {moment(box.ConfirmedMatch?.M?.Timestamp?.S).format('MMMM Do YYYY, h:mm:ss a') || 'Unknown'}
                </>
            );
        } else {
            return (
                <>
                    <strong>Recognized Text:</strong> {box.RecognizedText?.S || 'No recognized text'}
                    <br />
                    <strong>LLM Analysis:</strong> {box.AnalysedText?.M?.GameName?.S || 'No analysis available'}
                    <br />
                    <strong>Confidence:</strong> {(parseFloat(box.Confidence?.N) || 0).toFixed(2)}%
                </>
            );
        }
    };
    



    
      return (
        <View margin="2rem" position="relative">
            <Heading level={2}>Time Machine</Heading>
    
            <SelectField label="Select Website" value={selectedWebsite} onChange={handleWebsiteChange}>
                <option value="" disabled>
                    Select a website
                </option>
                {websites.map((website) => (
                    <option key={website} value={website}>
                        {website}
                    </option>
                ))}
            </SelectField>
    
            <Text backgroundColor="lightgray" padding="0.5rem" marginTop="1rem">
                {analysisStatus}
            </Text>
    
            {error && <Alert variation="error">{error}</Alert>}
    
            <Flex justifyContent="center" alignItems="center" marginTop="1rem">
                <Button
                    variation="link"
                    onClick={handlePreviousScreenshot}
                    isDisabled={currentScreenshotIndex >= screenshots.length - 1}
                >
                    Older
                </Button>
                {currentScreenshot && <Text>{new Date(currentScreenshot.Timestamp?.S).toLocaleString()}</Text>}
                <Button variation="link" onClick={handleNextScreenshot} isDisabled={currentScreenshotIndex <= 0}>
                    Newer
                </Button>
            </Flex>
    
            {/* Tab Navigation */}
            <Tabs value={selectedTab} onChange={handleTabChange}>
                <Tab label="Screenshot" />
                <Tab label="Tiles" />
            </Tabs>
    
            {selectedTab === 0 && currentScreenshot ? (
                // Screenshot View
                <View position="relative">
                    {/* Use img tag with crossOrigin attribute */}
                    <img src={imageUrl} alt="Screenshot" crossOrigin="anonymous" style={{ width: '100%' }} />
                    {analysisResults &&
                        analysisResults.length > 0 &&
                        analysisResults.map((box, index) => (
                            <Tooltip
                                key={index}
                                title={renderTileTooltipContent(box)}
                                arrow
                                placement="top"
                            >
                                <View
                                    position="absolute"
                                    border="5px solid"
                                    borderColor={box.Status?.S === 'COMPLETE' ? 'green' : 'red'}
                                    left={`${parseFloat(box.BoundingBox?.M?.Left?.N) * 100}%`}
                                    top={`${parseFloat(box.BoundingBox?.M?.Top?.N) * 100}%`}
                                    width={`${parseFloat(box.BoundingBox?.M?.Width?.N) * 100}%`}
                                    height={`${parseFloat(box.BoundingBox?.M?.Height?.N) * 100}%`}
                                >
                                    {box.Status?.S === 'COMPLETE' && (
                                        <CheckCircleIcon
                                            sx={{
                                                position: 'absolute',
                                                top: 8,
                                                right: 8,
                                                color: 'green',
                                            }}
                                        />
                                    )}
                                </View>
                            </Tooltip>
                        ))}
                </View>
            ) : selectedTab === 1 && analysisResults ? (
                // Tiles View
                <View position="relative">
                    {loadingTiles && (
                        <View
                            position="absolute"
                            top="50%"
                            left="50%"
                            transform="translate(-50%, -50%)"
                            zIndex="1"
                        >
                            <CircularProgress />
                        </View>
                    )}
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Tile</TableCell>
                                <TableCell>Confidence Level (%)</TableCell>
                                <TableCell>Recognised Text</TableCell>
                                <TableCell>LLM Analysis</TableCell>
                                <TableCell>Exclusive Status</TableCell>
                                <TableCell>Jackpot Value</TableCell>
                                <TableCell>Completed Details</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tileImages.length > 0 && !loadingTiles ? (
                                analysisResults.map((box, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {tileImages[index] ? (
                                                (() => {
                                                    // Calculate the scaled dimensions
                                                    let scaledWidth = tileImages[index].width * 1.5;
                                                    let scaledHeight = tileImages[index].height * 1.5;
    
                                                    // Determine if scaling is needed
                                                    const maxDimension = Math.max(scaledWidth, scaledHeight);
    
                                                    if (maxDimension > 200) {
                                                        const scaleFactor = 200 / maxDimension;
                                                        scaledWidth = scaledWidth * scaleFactor;
                                                        scaledHeight = scaledHeight * scaleFactor;
                                                    }
    
                                                    return (
                                                        <img
                                                            src={tileImages[index].dataUrl}
                                                            alt={`Tile ${index}`}
                                                            style={{
                                                                width: `${scaledWidth}px`,
                                                                height: `${scaledHeight}px`,
                                                            }}
                                                        />
                                                    );
                                                })()
                                            ) : (
                                                <Text>Loading...</Text>
                                            )}
                                        </TableCell>
    
                                        <TableCell>{(parseFloat(box.Confidence?.N) || 0).toFixed(2)}</TableCell>
                                        <TableCell>{box.RecognizedText?.S}</TableCell>
                                        <TableCell>
                                            {box.AnalysedText?.M?.TileType?.S === 'PROMOTION' ? (
                                                <Text>Type: PROMOTION</Text>
                                            ) : (
                                                <>
                                                    <Text>Type: GAME</Text>
                                                    <Text>
                                                        Game Name: {box.AnalysedText?.M?.GameName?.S || 'Not Found'}
                                                    </Text>
                                                    <Text>Studio: {box.AnalysedText?.M?.Studio?.S || 'Not Found'}</Text>
                                                </>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {box.AnalysedText?.M?.Exclusive?.S || 'Not Found'}
                                        </TableCell>
                                        <TableCell>
                                            {box.AnalysedText?.M?.JackpotValue?.S || 'Not Found'}
                                        </TableCell>
                                        <TableCell>
                                            {box.Status?.S === 'COMPLETE' && (
                                                <>
                                                    <Text>Game ID: {box.ConfirmedMatch?.M?.GameID?.S || 'Unknown'}</Text>
                                                    <Text>Studio ID: {box.ConfirmedMatch?.M?.StudioID?.S || 'Unknown'}</Text>
                                                    <Text>
                                                        Completed At: {moment(box.ConfirmedMatch?.M?.Timestamp?.S).format('MMMM Do YYYY, h:mm:ss a') || 'Unknown'}
                                                    </Text>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                !loadingTiles && (
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            <Text>No tiles available</Text>
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    </Table>
                </View>
            ) : (
                <Text>{loadingScreenshots ? 'Loading screenshots...' : 'No screenshots available'}</Text>
            )}
        </View>
    );

}

export default withAuthenticator(TimeMachine);