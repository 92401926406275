import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, CircularProgress, LinearProgress, Box } from '@mui/material';
import axios from 'axios';

const URLBoxStatusTile = () => {
  const [usageData, setUsageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [subscriptionInfo, setSubscriptionInfo] = useState({
    renewalDate: null,
    totalCredits: 0,
    usedCredits: 0,
    daysUntilRenewal: 0
  });

  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;

  const fetchUsageData = async () => {
    try {
      const response = await axios.get(`${API_URL}/system-info/urlbox`, {
        headers: {
          'x-api-key': API_KEY
        }
      });
      
      const { usageData } = response.data;
      const totalCredits = parseInt(usageData.rendersAllowed, 10);
      const usedCredits = parseInt(usageData.rendersUsed, 10);
      const renewalDate = new Date(usageData.rendersReset);
      
      // Calculate days until renewal
      const now = new Date();
      const daysUntilRenewal = Math.ceil((renewalDate - now) / (1000 * 60 * 60 * 24));

      setUsageData(usageData);
      setSubscriptionInfo({
        renewalDate: renewalDate,
        totalCredits: totalCredits,
        usedCredits: usedCredits,
        daysUntilRenewal
      });
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data every 5 minutes
  useEffect(() => {
    fetchUsageData();
    const interval = setInterval(fetchUsageData, 300000);
    return () => clearInterval(interval);
  }, []);

  const calculateDailyUsageRate = () => {
    const daysPassed = 30 - subscriptionInfo.daysUntilRenewal;
    if (daysPassed === 0) return 0;
    return subscriptionInfo.usedCredits / daysPassed;
  };

  const estimatedRemainingDays = () => {
    const dailyRate = calculateDailyUsageRate();
    if (dailyRate === 0) return subscriptionInfo.daysUntilRenewal;
    return Math.floor((subscriptionInfo.totalCredits - subscriptionInfo.usedCredits) / dailyRate);
  };

  const depletionDate = () => {
    const dailyRate = calculateDailyUsageRate();
    if (dailyRate === 0) return subscriptionInfo.renewalDate;
    const remainingDays = (subscriptionInfo.totalCredits - subscriptionInfo.usedCredits) / dailyRate;
    const depletionDate = new Date();
    depletionDate.setDate(depletionDate.getDate() + remainingDays);
    return depletionDate;
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error loading URLBox status</Typography>;

  const progressValue = (subscriptionInfo.usedCredits / subscriptionInfo.totalCredits) * 100;
  const isDepletionBeforeReset = depletionDate() < subscriptionInfo.renewalDate;

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">URLBox Status</Typography>
        <Box>
          <LinearProgress 
            variant="determinate" 
            value={progressValue} 
            style={{ 
              height: '20px', // Make the bar thicker
              backgroundColor: isDepletionBeforeReset ? 'red' : 'green' // Show in green if not running out of renders
            }}
          />
          <Typography>
            {subscriptionInfo.usedCredits} / {subscriptionInfo.totalCredits} credits used
          </Typography>
          <Typography>
            Renews in {subscriptionInfo.daysUntilRenewal} days
          </Typography>
          <Typography>
            Estimated days until depletion: {estimatedRemainingDays()}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default URLBoxStatusTile;