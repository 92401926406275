import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableRow, TableCell, TableBody, TableHead, Typography, CircularProgress, Box } from '@mui/material';

const ManageModels = () => {
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(true);

  const API_URL = process.env.REACT_APP_API_ANALYST_URL;
  const API_KEY = process.env.REACT_APP_API_ANALYST_KEY;

  // Fetch models on component mount and set interval for 10 seconds
  useEffect(() => {
    fetchModels();
    const intervalId = setInterval(() => {
      fetchModels();
    }, 10000); // Refresh every 10 seconds

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, []);

  const fetchModels = async () => {
    try {
      const response = await axios.get(`${API_URL}/rekognition/models`, {
        headers: { 'x-api-key': API_KEY }
      });
      setModels(response.data.models);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching models:', error);
      setLoading(false);
    }
  };

  const getModelName = (arn) => {
    const arnParts = arn.split('/');
    return arnParts[arnParts.length - 2]; // Extract the model name part
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString(); // Formats to local date and time string
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Manage Models
      </Typography>

      {models.length === 0 ? (
        <Typography>No models available</Typography>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Model Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>F1 Score</TableCell>
              <TableCell>Creation Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {models.map((model) => (
              <TableRow key={model.ProjectVersionArn}>
                <TableCell>{getModelName(model.ProjectVersionArn)}</TableCell>
                <TableCell>{model.Status}</TableCell>
                <TableCell>{model.EvaluationResult?.F1Score?.toFixed(2) ?? 'N/A'}</TableCell>
                <TableCell>{formatDate(model.CreationTimestamp)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default ManageModels;