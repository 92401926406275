import React, { useState, useEffect } from 'react';
import { Button, Table, TableRow, TableCell, TableBody, TableHead, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Checkbox, FormControlLabel, Select, MenuItem, InputLabel, FormControl, Modal, Box, Typography, TableSortLabel } from '@mui/material';

const WebsitesConfiguration = () => {
  const [websites, setWebsites] = useState([]); // Store the list of websites
  const [proxies, setProxies] = useState([]); // Store the list of proxies
  const [open, setOpen] = useState(false); // Controls dialog visibility
  const [screenshot, setScreenshot] = useState(null); // Store screenshot data
  const [isTesting, setIsTesting] = useState(false); // Flag for test state
  const [screenshotModalOpen, setScreenshotModalOpen] = useState(false); // Modal visibility control
  const [selectedWebsite, setSelectedWebsite] = useState(null); // Track the website being edited
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [formState, setFormState] = useState({
    name: '',
    url: '',
    proxyId: '', // The proxy ID field in WebsitesDB
    renderMode: 'Viewport', // Default to "Viewport"
    UBblockAdds: false,
    UBhideCookieBanners: false,
    UBclickAccept: false,
    UBdelay: 0,
    UBviewportWidth: 1280,
    UBviewportHeight: 720,
    cookies: '', // New cookies field
    updatedAt: '',
  });

  useEffect(() => {
    fetchWebsites();
    fetchProxies(); // Fetch proxies on component mount
  }, []);

  // Fetch all website configurations
  const fetchWebsites = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/websites`, {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
      });
      const data = await response.json();
      setWebsites(data);
    } catch (error) {
      console.error('Error fetching websites:', error);
    }
  };

  // Fetch all available proxies
  const fetchProxies = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/proxys`, {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
      });
      const data = await response.json();
      setProxies(data); // Store proxies in state
    } catch (error) {
      console.error('Error fetching proxies:', error);
    }
  };

// Functions to enable table sorting
const handleRequestSort = (property) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrder(isAsc ? 'desc' : 'asc');
  setOrderBy(property);
};

const sortedWebsites = [...websites].sort((a, b) => {
  if (orderBy === 'name') {
      return order === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
  }
  if (orderBy === 'url') {
      return order === 'asc' ? a.url.localeCompare(b.url) : b.url.localeCompare(a.url);
  }
  if (orderBy === 'proxyId') {
      const proxyA = proxies.find(proxy => proxy.id === a.proxyId)?.name || 'Unknown Proxy';
      const proxyB = proxies.find(proxy => proxy.id === b.proxyId)?.name || 'Unknown Proxy';
      return order === 'asc' ? proxyA.localeCompare(proxyB) : proxyB.localeCompare(proxyA);
  }
  if (orderBy === 'renderMode') {
      return order === 'asc' ? a.renderMode.localeCompare(b.renderMode) : b.renderMode.localeCompare(a.renderMode);
  }
  if (orderBy === 'UBblockAdds') {
      return order === 'asc' ? (a.UBblockAdds === b.UBblockAdds ? 0 : a.UBblockAdds ? -1 : 1) : (a.UBblockAdds === b.UBblockAdds ? 0 : a.UBblockAdds ? 1 : -1);
  }
  if (orderBy === 'UBhideCookieBanners') {
      return order === 'asc' ? (a.UBhideCookieBanners === b.UBhideCookieBanners ? 0 : a.UBhideCookieBanners ? -1 : 1) : (a.UBhideCookieBanners === b.UBhideCookieBanners ? 0 : a.UBhideCookieBanners ? 1 : -1);
  }
  if (orderBy === 'UBclickAccept') {
      return order === 'asc' ? (a.UBclickAccept === b.UBclickAccept ? 0 : a.UBclickAccept ? -1 : 1) : (a.UBclickAccept === b.UBclickAccept ? 0 : a.UBclickAccept ? 1 : -1);
  }
  if (orderBy === 'UBdelay') {
      return order === 'asc' ? a.UBdelay - b.UBdelay : b.UBdelay - a.UBdelay;
  }
  if (orderBy === 'UBviewportWidth') {
      return order === 'asc' ? a.UBviewportWidth - b.UBviewportWidth : b.UBviewportWidth - a.UBviewportWidth;
  }
  if (orderBy === 'UBviewportHeight') {
      return order === 'asc' ? a.UBviewportHeight - b.UBviewportHeight : b.UBviewportHeight - a.UBviewportHeight;
  }
  if (orderBy === 'updatedAt') {
      return order === 'asc' ? new Date(a.updatedAt) - new Date(b.updatedAt) : new Date(b.updatedAt) - new Date(a.updatedAt);
  }
  return 0;
});

//end of sorting functions



  // Open dialog to add or edit website
  const handleOpen = (website = null) => {
    setSelectedWebsite(website);
    setFormState(website || { 
      name: '', 
      url: '', 
      proxyId: '', // Initialize with empty or existing proxyId
      renderMode: 'Viewport', // Default to "Viewport"
      UBblockAdds: false, 
      UBhideCookieBanners: false, 
      UBclickAccept: false, 
      UBdelay: 0, 
      UBviewportWidth: 1280, 
      UBviewportHeight: 720, 
      cookies: '', // Initialize cookies as empty
      updatedAt: '' 
    });
    setOpen(true);
  };

  // Close dialog
  const handleClose = () => {
    setOpen(false);
    setSelectedWebsite(null);
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  // Handle checkbox changes
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormState({ ...formState, [name]: checked });
  };

  // Submit new or updated website configuration
  const handleSubmit = async () => {
    try {
      const url = selectedWebsite
        ? `${process.env.REACT_APP_API_URL}/websites/${selectedWebsite.id}`
        : `${process.env.REACT_APP_API_URL}/websites`;
  
      const method = selectedWebsite ? 'PATCH' : 'POST';
  
      // Set default values for missing fields
      const formData = {
        ...formState,
        proxyId: formState.proxyId || '', // Save empty string for "No Proxy"
        UBdelay: formState.UBdelay || 0, // Default delay to 0 if missing
        UBviewportWidth: formState.UBviewportWidth || 1280, // Default viewport width
        UBviewportHeight: formState.UBviewportHeight || 720, // Default viewport height
        UBblockAdds: formState.UBblockAdds !== undefined ? formState.UBblockAdds : true, // Default block adds to true
        UBhideCookieBanners: formState.UBhideCookieBanners !== undefined ? formState.UBhideCookieBanners : false,
        UBclickAccept: formState.UBclickAccept !== undefined ? formState.UBclickAccept : false,
        cookies: formState.cookies || '', // Ensure cookies field is always present
      };
  
      console.log('Form Data:', formData);
  
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        fetchWebsites(); // Refresh list
        handleClose(); // Close dialog
      } else {
        const errorData = await response.json();
        throw new Error(`Error: ${response.status} - ${JSON.stringify(errorData)}`);
      }
    } catch (error) {
      console.error('Error saving website configuration:', error);
    }
  };

  // Delete a website configuration
  const handleDeleteWebsite = async (website) => {
    try {
      console.log('Deleting website with ID:', website.id);
  
      const response = await fetch(`${process.env.REACT_APP_API_URL}/websites/${website.id}`, {
        method: 'DELETE',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
      });
  
      if (response.ok) {
        console.log('Website deleted successfully:', website.id);
        fetchWebsites(); // Refresh list
      } else {
        const errorData = await response.json();
        console.error('Error deleting website:', response.status, errorData);
      }
    } catch (error) {
      console.error('Error deleting website configuration:', error);
    }
  };

  // Test screenshot functionality
  const handleTestWebsite = async (website) => {
    setIsTesting(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/websites/${website.id}/screenshot`, {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
      });
      
      if (response.ok) {
        const data = await response.json();
        setScreenshot(data.screenshot); // Set the screenshot in state
        setScreenshotModalOpen(true); // Open the screenshot modal
      } else {
        console.error('Error taking screenshot:', response.statusText);
      }
    } catch (error) {
      console.error('Error taking screenshot:', error);
    } finally {
      setIsTesting(false);
    }
  };

  // Close the screenshot modal
  const handleCloseScreenshotModal = () => {
    setScreenshotModalOpen(false);
    setScreenshot(null);
  };

  return (
    <div>
      <h2>Website Configurations</h2>
      <Button onClick={() => handleOpen()}>Add Website</Button>

      <Table>
    <TableHead>
        <TableRow>
            <TableCell>
                <TableSortLabel
                    active={orderBy === 'name'}
                    direction={orderBy === 'name' ? order : 'asc'}
                    onClick={() => handleRequestSort('name')}
                >
                    Name
                </TableSortLabel>
            </TableCell>
            <TableCell>
                <TableSortLabel
                    active={orderBy === 'url'}
                    direction={orderBy === 'url' ? order : 'asc'}
                    onClick={() => handleRequestSort('url')}
                >
                    Website Address
                </TableSortLabel>
            </TableCell>
            <TableCell>
                <TableSortLabel
                    active={orderBy === 'proxyId'}
                    direction={orderBy === 'proxyId' ? order : 'asc'}
                    onClick={() => handleRequestSort('proxyId')}
                >
                    Active Proxy
                </TableSortLabel>
            </TableCell>
            <TableCell>
                <TableSortLabel
                    active={orderBy === 'renderMode'}
                    direction={orderBy === 'renderMode' ? order : 'asc'}
                    onClick={() => handleRequestSort('renderMode')}
                >
                    Render Mode
                </TableSortLabel>
            </TableCell>
            <TableCell>
                <TableSortLabel
                    active={orderBy === 'UBblockAdds'}
                    direction={orderBy === 'UBblockAdds' ? order : 'asc'}
                    onClick={() => handleRequestSort('UBblockAdds')}
                >
                    Block Ads?
                </TableSortLabel>
            </TableCell>
            <TableCell>
                <TableSortLabel
                    active={orderBy === 'UBhideCookieBanners'}
                    direction={orderBy === 'UBhideCookieBanners' ? order : 'asc'}
                    onClick={() => handleRequestSort('UBhideCookieBanners')}
                >
                    Hide Cookie Dialogs?
                </TableSortLabel>
            </TableCell>
            <TableCell>
                <TableSortLabel
                    active={orderBy === 'UBclickAccept'}
                    direction={orderBy === 'UBclickAccept' ? order : 'asc'}
                    onClick={() => handleRequestSort('UBclickAccept')}
                >
                    Click Accept?
                </TableSortLabel>
            </TableCell>
            <TableCell>
                <TableSortLabel
                    active={orderBy === 'UBdelay'}
                    direction={orderBy === 'UBdelay' ? order : 'asc'}
                    onClick={() => handleRequestSort('UBdelay')}
                >
                    Delay (ms)
                </TableSortLabel>
            </TableCell>
            <TableCell>
                <TableSortLabel
                    active={orderBy === 'UBviewportWidth'}
                    direction={orderBy === 'UBviewportWidth' ? order : 'asc'}
                    onClick={() => handleRequestSort('UBviewportWidth')}
                >
                    Screen Width
                </TableSortLabel>
            </TableCell>
            <TableCell>
                <TableSortLabel
                    active={orderBy === 'UBviewportHeight'}
                    direction={orderBy === 'UBviewportHeight' ? order : 'asc'}
                    onClick={() => handleRequestSort('UBviewportHeight')}
                >
                    Screen Height
                </TableSortLabel>
            </TableCell>
            <TableCell>
                <TableSortLabel
                    active={orderBy === 'updatedAt'}
                    direction={orderBy === 'updatedAt' ? order : 'asc'}
                    onClick={() => handleRequestSort('updatedAt')}
                >
                    Last Updated
                </TableSortLabel>
            </TableCell>
            <TableCell>Actions</TableCell>
        </TableRow>
    </TableHead>
    <TableBody>
        {sortedWebsites.map((website) => (
            <TableRow key={website.id}>
                <TableCell>{website.name}</TableCell>
                <TableCell>{website.url}</TableCell>
                <TableCell>{website.proxyId ? proxies.find(proxy => proxy.id === website.proxyId)?.name || 'Unknown Proxy' : 'No Proxy'}</TableCell>
                <TableCell>{website.renderMode}</TableCell>
                <TableCell>{website.UBblockAdds ? 'Yes' : 'No'}</TableCell>
                <TableCell>{website.UBhideCookieBanners ? 'Yes' : 'No'}</TableCell>
                <TableCell>{website.UBclickAccept ? 'Yes' : 'No'}</TableCell>
                <TableCell>{website.UBdelay}</TableCell>
                <TableCell>{website.UBviewportWidth}</TableCell>
                <TableCell>{website.UBviewportHeight}</TableCell>
                <TableCell>
                    {new Date(website.updatedAt).toLocaleString(undefined, {
                        month: 'short',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                    })}
                </TableCell>
                <TableCell>
                    <Button onClick={() => handleOpen(website)}>Edit</Button>
                    <Button onClick={() => handleDeleteWebsite(website)}>Delete</Button>
                    <Button onClick={() => handleTestWebsite(website)} disabled={isTesting}>{isTesting ? 'Testing...' : 'Test'}</Button>
                </TableCell>
            </TableRow>
        ))}
    </TableBody>
</Table>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{selectedWebsite ? 'Edit Website' : 'Add Website'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            type="text"
            fullWidth
            value={formState.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="url"
            label="URL"
            type="text"
            fullWidth
            value={formState.url}
            onChange={handleChange}
          />
          
          {/* Proxy Dropdown */}
          <FormControl fullWidth margin="dense">
            <InputLabel>Active Proxy</InputLabel>
            <Select
              name="proxyId"
              value={formState.proxyId}
              onChange={handleChange}
            >
              <MenuItem value="">No Proxy</MenuItem>
              {proxies.map((proxy) => (
                <MenuItem key={proxy.id} value={proxy.id}>
                  {proxy.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <FormControl fullWidth margin="dense">
            <InputLabel>Render Mode</InputLabel>
            <Select
              name="renderMode"
              value={formState.renderMode}
              onChange={handleChange}
            >
              <MenuItem value="Viewport">Viewport</MenuItem>
              <MenuItem value="Fullpage">Full Page</MenuItem>
            </Select>
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                checked={formState.UBblockAdds}
                onChange={handleCheckboxChange}
                name="UBblockAdds"
              />
            }
            label="Block Ads"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formState.UBhideCookieBanners}
                onChange={handleCheckboxChange}
                name="UBhideCookieBanners"
              />
            }
            label="Hide Cookie Dialogs"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formState.UBclickAccept}
                onChange={handleCheckboxChange}
                name="UBclickAccept"
              />
            }
            label="Click Accept"
          />
          <TextField
            margin="dense"
            name="UBdelay"
            label="Delay (ms)"
            type="number"
            fullWidth
            value={formState.UBdelay}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="UBviewportWidth"
            label="Viewport Width"
            type="number"
            fullWidth
            value={formState.UBviewportWidth}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="UBviewportHeight"
            label="Viewport Height"
            type="number"
            fullWidth
            value={formState.UBviewportHeight}
            onChange={handleChange}
          />
          
          {/* New Cookies TextField */}
          <TextField
            margin="dense"
            name="cookies"
            label="Cookies (JSON format)"
            type="text"
            fullWidth
            value={formState.cookies}
            onChange={handleChange}
          />
        </DialogContent>
        <Typography variant="body1">Note the you can use multiple cookies with ; as a separator</Typography>
        
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>{selectedWebsite ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>

      {/* Screenshot Modal */}
      <Modal
        open={screenshotModalOpen}
        onClose={handleCloseScreenshotModal}
        aria-labelledby="screenshot-modal-title"
        aria-describedby="screenshot-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          maxHeight: '90vh',
          maxWidth: '90vw',
          overflow: 'auto',
        }}>
          <h2 id="screenshot-modal-title">Screenshot</h2>
          {screenshot && <img src={`data:image/png;base64,${screenshot}`} alt="Screenshot" style={{ maxWidth: '100%' }} />}
        </Box>
      </Modal>
    </div>
  );
};

export default WebsitesConfiguration;
